import React from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { PDFDocument } from 'pdf-lib'

import solicitationLetter from 'files/solicitacao.pdf'

import Layout from 'layouts/index'
import TitleBox from 'components/TitleBox'
import Button from 'components/Button'

import { PageContainer } from 'pageStyles/sobre-nos.styles'

import {
  FormContainer,
  Text,
  InputContainer,
  Label,
  FormInput,
  ErrorText,
  FormTextArea,
  FormCheckbox,
  FormRadio,
  FormSubtitle,
  RadioContainer,
  RadioRow,
  Row,
} from 'pageStyles/carta-encaminhamento.styles'

interface Inputs {
  name: string // o
  email: string
  animalName: string
  weight: string // o
  specie: string
  age: number // o
  phone: string
  carpoLd: 'marked'
  carpoLe: 'marked'
  tarsoLd: 'marked'
  tarsoLe: 'marked'
  ombroLd: 'marked'
  ombroLe: 'marked'
  cotoveloLd: 'marked'
  cotoveloLe: 'marked'
  joelhoLd: 'marked'
  joelhoLe: 'marked'
  umeroLd: 'marked'
  umeroLe: 'marked'
  radioLd: 'marked'
  radioLe: 'marked'
  femurLd: 'marked'
  femurLe: 'marked'
  tibiaLd: 'marked'
  tibiaLe: 'marked'
  skullRadiography: 'marked'
  toraxRadiography: 'marked'
  abdomenRadiography: 'marked'
  cervical: 'marked'
  cervitoracica: 'marked'
  toracica: 'marked'
  toracolombar: 'marked'
  lombar: 'marked'
  lombossacra: 'marked'
  radiografiaDePelve: 'marked'
  radiografiaDeCervicalMoles: 'marked'
  ultrassonografiaAbdominal: 'marked'
  ultrassonografiaCervical: 'marked'
  ultrassonografiaOcular: 'marked'
  perfilDeExames: 'marked'
  suspeitaClinica: string
  medicoRequisitante: string
  crmv: string
  emailMedicoRequisitante: string
}

const inputToForm = {
  name: 'cliente',
  email: 'email',
  animalName: 'nome_do_animal',
  weight: 'peso',
  specie: 'raca',
  age: 'idade',
  phone: 'telefone',
  carpoLd: 'carpo_ld',
  carpoLe: 'carpo_le',
  tarsoLd: 'tarso_ld',
  tarsoLe: 'tarso_le',
  ombroLd: 'ombro_ld',
  ombroLe: 'ombro_le',
  cotoveloLd: 'cotovelo_ld',
  cotoveloLe: 'cotovelo_le',
  joelhoLd: 'joelho_ld',
  joelhoLe: 'joelho_le',
  umeroLd: 'umero_ld',
  umeroLe: 'umero_le',
  radioLd: 'radio_ld',
  radioLe: 'radio_le',
  femurLd: 'femur_ld',
  femurLe: 'femur_le',
  tibiaLd: 'tibia_ld',
  tibiaLe: 'tibia_le',
  skullRadiography: 'radiografia_de_cranio',
  toraxRadiography: 'radiografia_de_torax',
  abdomenRadiography: 'radiografia_de_abdome',
  cervical: 'cervical',
  cervitoracica: 'cervitoracia',
  toracica: 'toracica',
  toracolombar: 'toracolombar',
  lombar: 'lombar',
  lombossacra: 'lombossacra',
  radiografiaDePelve: 'radiografia_de_pelve',
  radiografiaDeCervicalMoles: 'radiografia_de_cervical',
  ultrassonografiaAbdominal: 'ultrassonografia_abdominal',
  ultrassonografiaCervical: 'ultrassonografia_cervical',
  ultrassonografiaOcular: 'ultrassonografia_ocular',
  perfilDeExames: 'perfil_de_exames',
  suspeitaClinica: 'suspeita_clinica',
  medicoRequisitante: 'medico_veterinario',
  crmv: 'crmv',
  emailMedicoRequisitante: 'email_medico',
}

const inverted = Object.assign(
  {},
  ...Object.entries(inputToForm).map(([a, b]) => ({ [b]: a }))
)

const CartaSolicitacao = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>()

  const onSubmit: SubmitHandler<Inputs> = async data => {
    fetch(solicitationLetter)
      .then(response => response.blob())
      .then(blob => {
        const reader = new FileReader()
        const pdfBlob = new Blob([blob as BlobPart], {
          type: 'application/pdf',
        })
        reader.readAsArrayBuffer(pdfBlob)
        reader.onload = async () => {
          if (reader.result) {
            const pdfDoc = await PDFDocument.load(reader.result)
            const form = pdfDoc.getForm()
            const fields = form.getFields()
            fields.forEach(field => {
              const name = field.getName()
              try {
                const textToFill = form.getTextField(name)
                textToFill.setText(data[inverted[name]])
              } catch (e) {}

              try {
                const checkToFill = form.getCheckBox(name)
                if (data[inverted[name]] === 'marked') checkToFill.check()
                if (Object.values(data).some(el => el === inverted[name]))
                  checkToFill.check()
              } catch (e) {}
            })
            const pdfBytes = await pdfDoc.save()
            const pdfFormBlob = new Blob([pdfBytes], {
              type: 'application/pdf',
            })
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(
                pdfFormBlob,
                'carta_solicitacao'
              )
            } else {
              var a = document.createElement('a')
              document.body.appendChild(a)
              var csvUrl = URL.createObjectURL(pdfFormBlob)
              a.href = csvUrl
              a.download = 'cata_solicitacao'
              a.click()
              URL.revokeObjectURL(a.href)
              a.remove()
            }
          }
        }
      })
  }

  return (
    <Layout>
      <>
        <TitleBox title='Carta de Solicitação de Imagem' bg='' />
        <Text>
          Preencha os campos abaixo e clique em 'Confirmar' para fazer o
          download da carta.
        </Text>
        <PageContainer>
          <FormContainer onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <InputContainer>
                <Label>Cliente*</Label>
                <FormInput
                  type='text'
                  {...register('name', { required: true })}
                />
                {errors.name && errors.name.type === 'required' && (
                  <ErrorText>Campo obrigatório</ErrorText>
                )}
              </InputContainer>
              <InputContainer>
                <Label>Peso(kg)*</Label>
                <FormInput
                  type='number'
                  {...register('weight', { required: true })}
                />
                {errors.weight && errors.weight.type === 'required' && (
                  <ErrorText>Campo obrigatório</ErrorText>
                )}
              </InputContainer>
            </Row>
            <Row>
              <InputContainer>
                <Label>Email</Label>
                <FormInput type='email' {...register('email')} />
              </InputContainer>
              <InputContainer>
                <Label>Nome do animal*</Label>
                <FormInput
                  type='text'
                  {...register('animalName', { required: true })}
                />
                {errors.animalName && errors.animalName.type === 'required' && (
                  <ErrorText>Campo obrigatório</ErrorText>
                )}
              </InputContainer>
            </Row>
            <Row>
              <InputContainer>
                <Label>Espécie</Label>
                <FormInput {...register('specie')} />
              </InputContainer>
              <InputContainer>
                <Label>Idade*</Label>
                <FormInput
                  type='number'
                  {...register('age', { required: true })}
                />
                {errors.age && errors.age.type === 'required' && (
                  <ErrorText>Campo obrigatório</ErrorText>
                )}
              </InputContainer>
            </Row>
            <Row>
              <InputContainer>
                <Label>Telefone</Label>
                <FormInput type='phone' {...register('phone')} />
              </InputContainer>
            </Row>
            <Row>
              <FormSubtitle>Radiografia de Articulações</FormSubtitle>
            </Row>
            <Row>
              <RadioContainer>
                <Label>Carpo/falanges</Label>
                <RadioRow>
                  <Label>LD</Label>
                  <FormCheckbox
                    type='checkbox'
                    value='marked'
                    {...register('carpoLd')}
                  />
                  <Label>LE</Label>
                  <FormCheckbox
                    type='checkbox'
                    value='marked'
                    {...register('carpoLe')}
                  />
                </RadioRow>
              </RadioContainer>
            </Row>
            <Row>
              <RadioContainer>
                <Label>Tarso/falanges</Label>
                <RadioRow>
                  <Label>LD</Label>
                  <FormCheckbox
                    type='checkbox'
                    value='marked'
                    {...register('tarsoLd')}
                  />
                  <Label>LE</Label>
                  <FormCheckbox
                    type='checkbox'
                    value='marked'
                    {...register('tarsoLe')}
                  />
                </RadioRow>
              </RadioContainer>
            </Row>
            <Row>
              <RadioContainer>
                <Label>Ombro</Label>
                <RadioRow>
                  <Label>LD</Label>
                  <FormCheckbox
                    type='checkbox'
                    value='marked'
                    {...register('ombroLd')}
                  />
                  <Label>LE</Label>
                  <FormCheckbox
                    type='checkbox'
                    value='marked'
                    {...register('ombroLe')}
                  />
                </RadioRow>
              </RadioContainer>
            </Row>
            <Row>
              <RadioContainer>
                <Label>Cotovelo</Label>
                <RadioRow>
                  <Label>LD</Label>
                  <FormCheckbox
                    type='checkbox'
                    value='marked'
                    {...register('cotoveloLd')}
                  />
                  <Label>LE</Label>
                  <FormCheckbox
                    type='checkbox'
                    value='marked'
                    {...register('cotoveloLe')}
                  />
                </RadioRow>
              </RadioContainer>
            </Row>
            <Row>
              <RadioContainer>
                <Label>Joelho</Label>
                <RadioRow>
                  <Label>LD</Label>
                  <FormCheckbox
                    type='checkbox'
                    value='marked'
                    {...register('joelhoLd')}
                  />
                  <Label>LE</Label>
                  <FormCheckbox
                    type='checkbox'
                    value='marked'
                    {...register('joelhoLe')}
                  />
                </RadioRow>
              </RadioContainer>
            </Row>
            <Row>
              <FormSubtitle>Radiografia de Membros</FormSubtitle>
            </Row>
            <Row>
              <RadioContainer>
                <Label>Úmero</Label>
                <RadioRow>
                  <Label>LD</Label>
                  <FormCheckbox
                    type='checkbox'
                    value='marked'
                    {...register('umeroLd')}
                  />
                  <Label>LE</Label>
                  <FormCheckbox
                    type='checkbox'
                    value='marked'
                    {...register('umeroLe')}
                  />
                </RadioRow>
              </RadioContainer>
            </Row>
            <Row>
              <RadioContainer>
                <Label>Rádio/Ulna</Label>
                <RadioRow>
                  <Label>LD</Label>
                  <FormCheckbox
                    type='checkbox'
                    value='marked'
                    {...register('radioLd')}
                  />
                  <Label>LE</Label>
                  <FormCheckbox
                    type='checkbox'
                    value='marked'
                    {...register('radioLe')}
                  />
                </RadioRow>
              </RadioContainer>
            </Row>
            <Row>
              <RadioContainer>
                <Label>Fêmur</Label>
                <RadioRow>
                  <Label>LD</Label>
                  <FormCheckbox
                    type='checkbox'
                    value='marked'
                    {...register('femurLd')}
                  />
                  <Label>LE</Label>
                  <FormCheckbox
                    type='checkbox'
                    value='marked'
                    {...register('femurLe')}
                  />
                </RadioRow>
              </RadioContainer>
            </Row>
            <Row>
              <RadioContainer>
                <Label>Tíbia/Fíbula</Label>
                <RadioRow>
                  <Label>LD</Label>
                  <FormCheckbox
                    type='checkbox'
                    value='marked'
                    {...register('tibiaLd')}
                  />
                  <Label>LE</Label>
                  <FormCheckbox
                    type='checkbox'
                    value='marked'
                    {...register('tibiaLe')}
                  />
                </RadioRow>
              </RadioContainer>
            </Row>
            <Row>
              <FormRadio
                type='radio'
                value='marked'
                {...register('skullRadiography')}
              />
              <Label>Radiografia de Crânio (necessário sedação)</Label>
            </Row>
            <Row>
              <FormRadio
                type='radio'
                value='marked'
                {...register('toraxRadiography')}
              />
              <Label>Radiografia de Torax</Label>
            </Row>
            <Row>
              <FormRadio
                type='radio'
                value='marked'
                {...register('abdomenRadiography')}
              />
              <Label>Radiografia de Abdome</Label>
            </Row>
            <Row>
              <FormSubtitle>
                Radiografia de Coluna (necessário sedação)
              </FormSubtitle>
            </Row>
            <Row>
              <FormRadio
                type='radio'
                value='marked'
                {...register('cervical')}
              />
              <Label>Cervical</Label>
            </Row>
            <Row>
              <FormRadio
                type='radio'
                value='marked'
                {...register('cervitoracica')}
              />
              <Label>Cervitorácical</Label>
            </Row>
            <Row>
              <FormRadio
                type='radio'
                value='marked'
                {...register('toracica')}
              />
              <Label>Torácica</Label>
            </Row>
            <Row>
              <FormRadio
                type='radio'
                value='marked'
                {...register('toracolombar')}
              />
              <Label>Toracolombar</Label>
            </Row>
            <Row>
              <FormRadio type='radio' value='marked' {...register('lombar')} />
              <Label>Lombar</Label>
            </Row>
            <Row>
              <FormRadio
                type='radio'
                value='marked'
                {...register('lombossacra')}
              />
              <Label>Lombossacra</Label>
            </Row>
            <Row>
              <FormRadio
                type='radio'
                value='marked'
                {...register('radiografiaDePelve')}
              />
              <Label>Radiografia de Pelve (necessário sedação</Label>
            </Row>
            <Row>
              <FormRadio
                type='radio'
                value='marked'
                {...register('radiografiaDeCervicalMoles')}
              />
              <Label>Radiografia de Cervical (Tecidos Moles)</Label>
            </Row>
            <Row>
              <FormRadio
                type='radio'
                value='marked'
                {...register('ultrassonografiaAbdominal')}
              />
              <Label>Ultrassonografia Abdominal l</Label>
            </Row>
            <Row>
              <FormRadio
                type='radio'
                value='marked'
                {...register('ultrassonografiaCervical')}
              />
              <Label>Ultrassonografia Cervical</Label>
            </Row>
            <Row>
              <FormRadio
                type='radio'
                value='marked'
                {...register('ultrassonografiaOcular')}
              />
              <Label>Ultrassonografia Ocular</Label>
            </Row>
            <Row>
              <FormRadio
                type='radio'
                value='marked'
                {...register('perfilDeExames')}
              />
              <Label>Perfil de exames - Radiografia e Ultrassonografia</Label>
            </Row>
            <Row>
              <InputContainer>
                <Label>Suspeita Clínica</Label>
                <FormTextArea {...register('suspeitaClinica')} />
              </InputContainer>
            </Row>
            <Row>
              <InputContainer>
                <Label>Médico Veterinário requisitante</Label>
                <FormInput {...register('medicoRequisitante')} />
              </InputContainer>
              <InputContainer>
                <Label>CRMV</Label>
                <FormInput {...register('crmv', { required: true })} />
                {errors.crmv && errors.crmv.type === 'required' && (
                  <ErrorText>Campo obrigatório</ErrorText>
                )}
              </InputContainer>
            </Row>
            <Row>
              <InputContainer>
                <Label>Email do médico veterinário requisitante</Label>
                <FormInput
                  type='email'
                  {...register('emailMedicoRequisitante')}
                />
              </InputContainer>
            </Row>
            <Row>
              <Button>Confirmar</Button>
            </Row>
          </FormContainer>
        </PageContainer>
      </>
    </Layout>
  )
}

export default CartaSolicitacao
